import { ColumnDef, PaginationState } from "@tanstack/react-table";
import React from "react";

import { UserFilterStatus } from "@/components/approval/user-filter-status";
import AvatarColumn from "@/components/common/avatar-column";
import { AttachmentIcon } from "@/components/icons/attachment-icon";
import { AttachmentIconWithStatus } from "@/components/icons/attachment-icon-with-status";
import { SearchFilter } from "@/components/search-filter";
import { DataTable } from "@/components/table/data-table";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import {
  downloadContract,
  downloadInsurance,
} from "@/components/university/approval/api";
import { Approval } from "@/components/university/approval/approval.model";
import { approvalStatusMapper } from "@/src/constants/status.constant";

export const getApprovalListColumns = ({
  customAvatarColumn,
  disabledInsuranceColumn = false,
  isUniversity = false,
}: {
  customAvatarColumn?: (rowData: any) => React.ReactNode;
  disabledInsuranceColumn?: boolean;
  isUniversity?: boolean;
}) => {
  const columns = [
    {
      header: "Name",
      cell: ({ row }) => {
        if (customAvatarColumn) return customAvatarColumn(row.original);
        return (
          <div className="max-w-[500px] whitespace-normal">
            <AvatarColumn
              linkDetail={`${isUniversity ? "/university" : "/hosting/lab"}/approvals/${row.original?.contracts?.[0]?.researcher_org_id}?contract_type=${row.original.contracts?.[0]?.contract_type}`}
              avatarName={row.original.researcher_org?.name}
              avatarName2={row.original?.user_org_name}
              avatarUrl={row.original.researcher_org?.logo_url}
            />
          </div>
        );
      },
    },
    {
      header: "Contract",
      cell: ({ row }) => {
        return (
          <Button
            variant="ghost"
            onClick={(e) => {
              e.stopPropagation();
              downloadContract(row.original);
            }}
          >
            <AttachmentIconWithStatus
              url="pdf"
              status={row.original?.status}
              className="mx-auto w-[32px] shrink-0"
            />
          </Button>
        );
      },
    },
    {
      header: "Type",
      cell: ({ row }) => (
        <span className="p4-medium-mobile capitalize text-black">
          {row.original.contracts?.[0]?.contract_type} Contract
        </span>
      ),
    },
    {
      header: "Proof Of Insurance",
      cell: ({ row }) => {
        if (
          row.original.insurances?.length > 0 &&
          row.original.registration_type !== "service"
        ) {
          return (
            <Button
              variant="ghost"
              onClick={(e) => {
                e.stopPropagation();
                downloadInsurance(row.original);
              }}
            >
              <AttachmentIcon url="pdf" className="mx-auto w-[32px] shrink-0" />
            </Button>
          );
        } else {
          return <span className="p4-medium">N/A</span>;
        }
      },
    },
    {
      header: "Status",
      cell: ({ row }) => {
        let stt =
          approvalStatusMapper[
            row.original?.status as keyof typeof approvalStatusMapper
          ];
        let approvalStatus = stt
          ? {
              text: stt.text,
              variant: stt.variant,
            }
          : {
              text: row.original?.status,
              variant: "warning",
            };

        return (
          <Badge
            className="p4 w-auto min-w-[112px] px-4 py-3"
            variant={approvalStatus.variant as any}
          >
            {approvalStatus.text}
          </Badge>
        );
      },
    },
  ] as ColumnDef<Approval>[];
  if (disabledInsuranceColumn) {
    const newColumns = columns.filter(
      (item) => item.header !== "Proof Of Insurance",
    );
    return newColumns;
  }
  return columns;
};

export function ApprovalList({
  data,
  filters,
  setFilters,
  pagination,
  setPagination,
  pageSize,
  disabledInsuranceColumn,
  placeholderSearch,
  customAvatarColumn,
}: {
  data: any;
  filters: any;
  setFilters: any;
  pagination: PaginationState;
  setPagination: any;
  pageSize: number;
  disabledInsuranceColumn?: boolean;
  placeholderSearch?: string;
  customAvatarColumn?: (rowData: any) => React.ReactNode;
}) {
  const columns = getApprovalListColumns({
    disabledInsuranceColumn,
    customAvatarColumn,
  });
  const userSummary = {
    count_approved: data?.count?.approved,
    count_pending: data?.count?.pending,
    count_rejected: data?.count?.rejected,
    count_all: data?.count?.all,
  };

  return (
    <div className="px-5 py-10">
      <div className="flex items-center justify-between border-b border-b-coffee-600 pb-2">
        <h2 className="text-emerald-black">Approvals</h2>
      </div>
      <div className="my-7 px-4 md:px-0">
        <SearchFilter
          placeholder={placeholderSearch || "Search for users"}
          filters={filters}
          setFilters={setFilters}
        />
      </div>
      <div className="mb-7">
        <UserFilterStatus
          filters={filters}
          setFilters={setFilters}
          summary={userSummary}
        />
      </div>
      <DataTable
        columns={columns}
        pageCount={
          Math.floor(data?.lab_registrations?.pagination?.total / pageSize) + 1
        }
        pagination={pagination}
        setPagination={setPagination}
        clickableRow
        hideNoResult
        data={data?.lab_registrations}
      />
    </div>
  );
}
