import { LISTING_STATUS_USER } from "@/components/common/constants";
import { cn } from "@/lib/utils";

const statuses = ["approved", "pending", "rejected"];

export function UserFilterStatus({ filters, setFilters, summary }: any) {
  function handleToggleStatus(status: string) {
    setFilters({ ...filters, status });
  }
  return (
    <div className="items-centers flex gap-3 py-1">
      <button
        className={cn(
          "p4 flex h-[42px] cursor-pointer select-none items-center justify-center gap-2 rounded-full bg-background px-4 font-bold text-coffee-500 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2",
          !filters.status && "bg-emerald-600 text-background",
        )}
        onClick={() => setFilters({ ...filters, status: undefined })}
        aria-label="Filter all bookings"
      >
        All{" "}
        <div
          className={cn(
            "p4 flex h-[22px] items-center justify-center rounded-[43px] bg-coffee-500 px-[8.5px] text-xs font-bold text-gold-50",
            !filters.status && "bg-background text-emerald-600",
          )}
        >
          {summary?.count_all || 0}
        </div>
      </button>
      {statuses?.map((status) => {
        return (
          <button
            key={status}
            className={cn(
              "p4 flex h-[42px] cursor-pointer select-none items-center justify-center gap-2 rounded-full bg-background px-4 font-bold text-coffee-500 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2",
              filters.status === status && "bg-emerald-600 text-background",
            )}
            onClick={() => handleToggleStatus(status)}
            aria-label={`Filter ${LISTING_STATUS_USER[status]} bookings`}
          >
            {LISTING_STATUS_USER[status]}
            <div
              className={cn(
                "p4 flex h-[22px] items-center justify-center rounded-[43px] bg-coffee-500 px-[8.5px] text-xs font-bold text-gold-50",
                filters.status === status && "bg-background text-emerald-600",
              )}
            >
              {summary?.[`count_${status}`] || 0}
            </div>
          </button>
        );
      })}
    </div>
  );
}
