/* eslint-disable @next/next/no-html-link-for-pages */
import { debounce } from "lodash-es";
import { Search, X } from "lucide-react";
import { useCallback, useState } from "react";

import { Input } from "@/components/ui/input";

interface Props {
  setFilters?: any;
  filters?: any;
  placeholder: string;
}

export function SearchFilter(props: Props) {
  const [search, setSearch] = useState(props.filters?.text || "");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const applySearch = useCallback(
    debounce((value) => {
      if (props.setFilters) {
        props.setFilters((prev: any) => ({
          ...prev,
          text: value,
        }));
      }
    }, 400),
    [],
  );

  function onChangeSearch(e: any) {
    setSearch(e.target.value);
    applySearch(e.target.value);
  }

  function clearSearch() {
    setSearch("");
    if (props.setFilters) {
      props.setFilters((prev: any) => ({
        ...prev,
        text: "",
      }));
    }
  }

  return (
    <div className="border-b border-b-slate-200">
      <div className="relative">
        <Input
          placeholder={props.placeholder}
          className="h-12 w-full text-base"
          onChange={onChangeSearch}
          value={search}
        />
        {Boolean(search) ? (
          <X
            size="16"
            onClick={clearSearch}
            className="absolute right-3 top-1/3 cursor-pointer"
          />
        ) : (
          <Search
            strokeWidth={1}
            className="absolute right-3 top-1/4 bg-interface-white stroke-coffee-500 md:bg-transparent"
          />
        )}
      </div>
    </div>
  );
}
