import {
  API_URL,
  downloadURI,
  handleError,
  superFetch,
  superFetchData,
} from "@/lib/utils";

export async function downloadContract(approval: any) {
  const contract = approval?.contracts?.[0];

  const res = await superFetchData(
    `/orgs/approvals?researcher_org_id=${contract?.researcher_org_id}&contract_type=${contract.contract_type}`,
  );
  downloadURI(
    res?.contracts?.[0]?.signed_contract_url,
    res?.contract?.[0]?.contract_type,
  );
}

export async function downloadInsurance(approval: any) {
  const insuranceDoc = approval?.insurances?.[0];

  try {
    const res = (await superFetch(
      `${API_URL}/users/documents?id=${insuranceDoc.id}&from_signed_contact_id=${approval?.contracts?.[0].signed_contract_id}`,
    )) as any;
    downloadURI(res.data.url, insuranceDoc.name);
  } catch (error) {
    handleError(error);
  }
}
