import Link from "next/link";
import React from "react";

import { UserAvatar } from "@/components/common/user-avatar";

function AvatarColumn({
  avatarName,
  avatarName2,
  avatarUrl,
  linkDetail,
}: {
  avatarName: string;
  avatarUrl: string;
  avatarName2: string;
  linkDetail: string;
}) {
  return (
    <Link
      href={`${linkDetail}`}
      className="p4-medium line-clamp-2 text-emerald-black hover:underline"
    >
      <div
        className="flex items-center gap-2"
        aria-label={`item ${avatarName}`}
      >
        <UserAvatar url={avatarUrl} name={avatarName} />
        <div>
          <div className="h7 text-[#262A34]">
            <div className="h7">{avatarName}</div>
          </div>
          <div className="p4 text-interface-2">{avatarName2}</div>
        </div>
      </div>
    </Link>
  );
}

export default AvatarColumn;
