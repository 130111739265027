import { Paperclip } from "lucide-react";
import React from "react";

import { ImageResponsive } from "@/components/image";
import { cn } from "@/lib/utils";

interface AttachmentIconProps {
  url: string;
  status: string;
  className?: string;
}

export const AttachmentIconWithStatus: React.FC<AttachmentIconProps> = ({
  url,
  status,
  className,
}) => {
  const type = url.split(".").pop();
  const iconSrc = getIconSrc(type);
  return (
    <div className={cn("relative shrink-0", className)}>
      {iconSrc ? (
        <ImageResponsive
          src={iconSrc}
          alt={`Attachment icon for ${type}`}
          className="display-block"
        />
      ) : (
        <Paperclip size={24} strokeWidth={1} className="display-block0" />
      )}
      <ImageResponsive
        src={getStatusIconSrc(status)}
        alt={`Check icon for ${type}`}
        className="size-xs absolute -bottom-2 -right-2"
        width={18}
        height={18}
      />
    </div>
  );
};

function getIconSrc(type?: string) {
  switch (type) {
    case "pdf":
      return "/icons/pdf.svg";
    case "jpg":
    case "jpeg":
    case "png":
      return "/icons/image.svg";
    case "docx":
      return "/icons/doc.svg";
    case "doc":
      return "/icons/doc.svg";
    case "txt":
      return "/icons/txt.svg";
    default:
      return "";
  }
}

function getStatusIconSrc(status: string) {
  const approveStatus = ["approved"];
  const declineStatus = ["uni_declined", "lab_declined"];
  const requestStatus = ["pending_lab_more_info", "pending_uni_more_info"];

  if (approveStatus.includes(status)) {
    return "/icons/approvals/check-green.svg";
  }
  if (declineStatus.includes(status)) {
    return "/icons/approvals/warning-red.svg";
  }
  if (requestStatus.includes(status)) {
    return "/icons/approvals/warning-orange.svg";
  }
  if (status == "voided") {
    return "/icons/approvals/close-grey.svg";
  }
  return "/icons/approvals/clock-orange.svg";
}
