export const approvalStatusMapper = {
  pending_lab_approve: {
    text: "Pending",
    variant: "pending",
  },
  pending_uni_approve: {
    text: "Conditionally Approved",
    variant: "pending",
  },
  voided: {
    text: "Voided",
    variant: "voided",
  },

  lab_declined: {
    text: "Declined",
    variant: "warning",
  },
  uni_declined: {
    text: "Declined",
    variant: "warning",
  },
  pending_lab_more_info: {
    text: "Information Requested",
    variant: "pending",
  },
  pending_uni_more_info: {
    text: "Information Requested",
    variant: "pending",
  },
  pending_researcher_subscription: {
    text: "Pending",
    variant: "pending",
  },
  pending_researcher_training: {
    text: "Pending",
    variant: "pending",
  },
  pending_researcher_insurance: {
    text: "Pending",
    variant: "pending",
  },
  lab_approved: {
    text: "Conditionally Approved",
    variant: "success",
  },
  approved: {
    text: "Approved",
    variant: "success",
  },
} as const;
