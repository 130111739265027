import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { cn } from "@/lib/utils";

interface Props {
  url: string | undefined;
  name: string;
  className?: string;
  isPdf?: boolean;
}

function getInitials(name: string) {
  if (!name) return "";
  try {
    return name
      .split(" ")
      .slice(0, 1)
      .map((word) => word[0].toUpperCase())
      .join("");
  } catch (error) {
    return "";
  }
}

export function UserAvatar({ url, name, className, isPdf }: Props) {
  return (
    <Avatar
      className={cn(
        "h7 h-12 w-12 overflow-hidden bg-coffee-200 text-base",
        className,
      )}
    >
      {url && (
        <AvatarImage
          src={url}
          alt="profile image"
          className="rounded-full border border-coffee-200 object-scale-down"
        />
      )}

      <AvatarFallback
        className={`flex items-center justify-center rounded-full border border-coffee-200 bg-coffee-100 text-coffee-400 ${
          isPdf ? "!pb-3" : ""
        }`}
      >
        {getInitials(name)}
      </AvatarFallback>
    </Avatar>
  );
}
