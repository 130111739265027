"use client";

import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  Row,
  useReactTable,
} from "@tanstack/react-table";
import { ChevronLeft, ChevronRight } from "lucide-react";
import React from "react";

import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { cn } from "@/lib/utils";

// This type is used to define the shape of our data.
// You can use a Zod schema here if you want.

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  pagination?: any;
  setPagination?: any;
  pageCount?: any;
  cellClassName?: string;
  headerClassName?: string;
  showHeaders?: boolean;
  hideNoResult?: boolean;
  clickableRow?: boolean;
  onHoverRow?: (row: TData) => void;
  onMouseOutRow?: () => void;
  expanded?: any;
  setExpanded?: any;
  expandedRow?: (value: any) => React.ReactNode;
  tableClassName?: string;
}

const isLastRow = (rows: Row<Record<string, string>>[], index: number) => {
  if (!Array.isArray(rows) || rows.length < 1) return false;
  return index + 1 === rows.length;
};

export function DataTable<TData, TValue>({
  columns,
  data = [],
  pagination,
  setPagination,
  pageCount,
  cellClassName,
  headerClassName,
  showHeaders = true,
  hideNoResult,
  clickableRow = false,
  onHoverRow,
  onMouseOutRow,
  expanded,
  setExpanded,
  expandedRow,
  tableClassName,
}: DataTableProps<TData, TValue>) {
  const table = useReactTable({
    data,
    columns,
    pageCount,
    state: {
      pagination,
      expanded: expanded ? true : undefined,
    },
    getRowId: (row: any) => row.id,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    // getPaginationRowModel: getPaginationRowModel(),
    manualPagination: true,
  });

  if (!data?.length) return null;

  return (
    <div className="">
      <Table
        className={cn(
          "border-separate border-spacing-y-2 rounded-md",
          tableClassName,
        )}
      >
        {showHeaders && (
          <TableHeader className="p3 [&_tr]:border-none">
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead
                      key={header.id}
                      className={cn("p3-medium text-black", headerClassName)}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
        )}
        <TableBody className="!max-w-[100vw] !overflow-x-scroll">
          {table.getRowModel().rows?.length
            ? table.getRowModel().rows.map((row, index) => {
                const isShowExpanded = expanded?.[row?.id];
                return (
                  <React.Fragment key={row.id}>
                    <TableRow
                      key={row.id}
                      data-state={row.getIsSelected() && "selected"}
                      className={cn(
                        "border-b-0",
                        // index % 2 !== 0 && "!border border-coffee-100",
                        clickableRow && "cursor-pointer",
                      )}
                      onMouseOver={() => {
                        if (onHoverRow) onHoverRow(row.original as any);
                      }}
                      onMouseOut={() => {
                        if (onMouseOutRow) onMouseOutRow();
                      }}
                      onClick={(event: any) => {
                        if (clickableRow) {
                          const row = event.target.closest("tr");
                          let link =
                            row?.firstElementChild?.querySelector("a") ??
                            row?.querySelector("a");
                          link?.click();
                        }
                      }}
                    >
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <TableCell
                            key={cell.id}
                            className={cn(
                              `p4 h-[72px] text-ellipsis whitespace-nowrap ${!isLastRow(table.getRowModel().rows, index) && "border-b"} border-coffee-100 px-5`,
                              cellClassName,
                            )}
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext(),
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                    {isShowExpanded && expandedRow ? (
                      <>
                        {expandedRow({
                          row,
                          clickableRow,
                          columnLength: columns.length,
                        })}
                      </>
                    ) : null}
                  </React.Fragment>
                );
              })
            : !hideNoResult && (
                <TableRow>
                  <TableCell
                    colSpan={columns.length}
                    className="h-24 text-center"
                  >
                    No results.
                  </TableCell>
                </TableRow>
              )}
        </TableBody>
      </Table>
      {pagination &&
        !!data?.length &&
        (table.getCanPreviousPage() || table.getCanNextPage()) && (
          <div className="mb-10 mt-[26px] flex items-center justify-end space-x-2">
            <Button
              variant="secondary"
              className="h-[49px] w-[49px]"
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
              aria-label="Previous page"
            >
              <ChevronLeft size={24} />
            </Button>
            <Button
              variant="secondary"
              className="h-[49px] w-[49px]"
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
              aria-label="Next page"
            >
              <ChevronRight size={24} />
            </Button>
          </div>
        )}
    </div>
  );
}
