import { Paperclip } from "lucide-react";
import React from "react";

import { ImageResponsive } from "@/components/image";

interface AttachmentIconProps {
  url: string;
  className?: string;
  width?: number | string;
  height?: number | string;
}

export const AttachmentIcon: React.FC<AttachmentIconProps> = ({
  url,
  className,
  width,
  height,
}) => {
  let iconSrc = "";

  const type = url.split(".").pop();

  switch (type) {
    case "pdf":
      iconSrc = "/icons/pdf.svg";
      break;
    case "jpg":
      iconSrc = "/icons/image.svg";
      break;
    case "png":
      iconSrc = "/icons/image.svg";
      break;
    case "docx":
      iconSrc = "/icons/doc.svg";
      break;
    case "doc":
      iconSrc = "/icons/doc.svg";
      break;
    case "txt":
      iconSrc = "/icons/txt.svg";
      break;
    default:
      return <Paperclip className={className} size={24} />;
  }

  return (
    <ImageResponsive
      src={iconSrc}
      alt={`Attachment icon for ${type}`}
      className={className}
      width={width}
      height={height}
    />
  );
};
