import { useEffect, useState } from "react";

import { cn } from "@/lib/utils";

interface Props extends React.ImgHTMLAttributes<HTMLImageElement> {
  src: string | undefined;
  alt: string;
  className?: string;
  width?: string | number;
  height?: string | number;
}

export function Image(props: Props) {
  const [error, setError] = useState(false);

  useEffect(() => {
    if (!props.src) return;
    const img = new (window as any).Image();
    img.src = props.src;
    img.onerror = () => {
      setError(true);
    };
  }, [props.src]);

  if (error || !props.src) {
    return (
      <div className="relative h-full w-full pb-[100%] first:row-span-2">
        <div className="absolute left-0 top-0 h-full w-full bg-slate-200"></div>
      </div>
    );
  }

  return (
    <div className="relative h-full w-full pb-[100%] first:row-span-2">
      <img
        {...props}
        alt={props.alt}
        className={`absolute left-0 top-0 h-full w-full object-cover ${props.className}`}
      />
    </div>
  );
}

export function ImageResponsive(props: Props) {
  const [error, setError] = useState(false);

  useEffect(() => {
    if (!props.src) return;
    const img = new (window as any).Image();
    img.src = props.src;
    img.onerror = () => {
      setError(true);
    };
  }, [props.src]);

  if (error || !props.src) {
    return (
      <div
        className={cn("bg-slate-200", props.className)}
        style={{ width: props.width, height: props.height }}
      ></div>
    );
  }

  return (
    <img
      {...props}
      alt={props.alt}
      className={cn("object-cover", props.className)}
      width={props.width}
      height={props.height}
    />
  );
}
